* {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system,  Osaka, "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"ＭＳ Ｐゴシック",
     'Segoe UI', 'Roboto', 'Oxygen', BlinkMacSystemFont,
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App{
  max-width: 1200px;
  width: 100%;
  min-height: 100vh;
  box-shadow: 0 0 20px #eee;
  margin: 0 auto;
  overflow: hidden;
}
a{
  text-decoration: none;
}
ul{
  list-style: none;
}
button{
  border: none;
  outline: none;
  cursor: pointer;
}

/* ----------------- Not Fount ------------------- */
 @import url("./components/body/notfound/notFound.css");